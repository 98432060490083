import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

import {cn} from '@/shared';

type HeadingProps = ComponentPropsWithoutRef<'h1'> & {
	/** Heading level. This will translate into any h1-6 level unless `asChild` is defined */
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	/** Changes text sizing
	 * @default 'xlarge'
	 */
	size?: 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge' | '3xlarge' | '4xlarge';
	/** Adds margin-bottom */
	spacing?: boolean;
	/**
	 * Change the default rendered element for the one passed as a child, merging their props and behavior.
	 * @default false
	 */
	asChild?: boolean;
};

/** Use `Heading` to render h1-6 elements with heading text styles.  */
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
	({level = 1, size = '4xlarge', spacing = false, className, asChild, ...rest}, ref) => {
		const Component = asChild ? Slot : `h${level ?? 1}`;

		return (
			<Component
				ref={ref}
				className={cn([
					'font-display',
					'font-bold',
					'text-[#290076]',
					size === 'small' && ['text-base'],
					size === 'medium' && ['text-lg'],
					size === 'large' && ['text-lg', 'md:text-xl'],
					size === 'xlarge' && ['text-xl', 'md:text-2xl'],
					size === '2xlarge' && ['text-2xl', 'md:text-3xl'],
					size === '3xlarge' && ['text-3xl', 'md:text-4xl'],
					size === '4xlarge' && ['text-4xl', 'md:text-5xl'],
					spacing && [
						size === 'small' && ['mb-3'],
						size === 'medium' && ['mb-3'],
						size === 'large' && ['mb-3'],
						size === 'xlarge' && ['mb-3'],
						size === '2xlarge' && ['mb-3'],
						size === '3xlarge' && ['mb-3'],
						size === '4xlarge' && ['mb-4'],
					],
					className,
				])}
				{...rest}
			/>
		);
	},
);

Heading.displayName = 'Heading';
