import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

import {cn} from '@/shared';

type ParagraphProps = ComponentPropsWithoutRef<'p'> & {
	/** Changes text sizing */
	size?: 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge' | '3xlarge' | '4xlarge';
	// /** Adds margin-bottom */
	// spacing?: boolean;
	// /** Reduces line-height for short paragraphs
	//  * @deprecated Use `variant="short"` instead
	//  */
	// short?: boolean;
	// /** Adjusts styling for paragraph length */
	// variant?: 'long' | 'short';
	/**
	 * Change the default rendered element for the one passed as a child, merging their props and behavior.
	 * @default false
	 */
	asChild?: boolean;
};

/** Use `Paragraph` to display text with paragraph text styles. */
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
	(
		{
			size = 'medium',
			// spacing = false,
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'p';

		return (
			<Component
				ref={ref}
				className={cn([
					size === 'small' && ['text-base'],
					size === 'medium' && ['text-lg'],
					size === 'large' && ['text-lg', 'md:text-xl'],
					size === 'xlarge' && ['text-xl', 'md:text-2xl'],
					size === '2xlarge' && ['text-2xl', 'md:text-3xl'],
					size === '3xlarge' && ['text-3xl', 'md:text-4xl'],
					size === '4xlarge' && ['text-4xl', 'md:text-5xl'],
					className,
				])}
				{...rest}
			/>
		);
	},
);

Paragraph.displayName = 'Paragraph';
